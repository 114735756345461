<template>
    <v-card class="ma-auto">
        <MyCardTitle  modulo="Paciente" :cardInfo="itemAtual"/>
        <v-card-title>
            <v-row>
                <v-col cols="11" class="text-end">
                    <v-btn icon @click="gravar()" ><v-icon>mdi-content-save</v-icon></v-btn>
                </v-col>
                <v-col cols="1" class="text-end">
                    <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field
                        v-model="itemAtual.nome"
                        label="Nome Completo"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field
                        v-model="itemAtual.cpf"
                        label="CPF"
                        outlined
                        v-mask="'###.###.###-##'"
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field
                        v-model="itemAtual.rg"
                        label="RG"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-autocomplete
                        v-model="itemAtual.orgao_exp"
                        label="Orgão Expedidor"
                        outlined
                        :items="orgao_exp"
                        item-text="nome"
                        item-value="id"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field
                        v-model="itemAtual.data_nascimento"
                        label="Data de Nascimento"
                        outlined
                        type="date"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" class="mb-2">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-autocomplete
                        outlined
                        :loading="loading_operadora"
                        v-model="itemAtual.operadora"
                        label="Operadora"
                        :items="operadoras"
                        item-text="nome"
                        item-value="id"
                        @change="get_planos()"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-autocomplete
                        outlined
                        :loading="loading_plano"
                        v-model="itemAtual.plano"
                        label="Plano"
                        :items="planos"
                        item-text="nome"
                        item-value="id"
                        @change="get_planos()"
                        return-object
                        ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-autocomplete
                        outlined
                        :loading="loading_modalidade"
                        v-model="itemAtual.modalidade"
                        label="Modalidade"
                        :items="modalidade"
                        item-text="nome"
                        item-value="id"
                        @change="get_modalidade()"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field
                        v-model="itemAtual.nr_carteirinha"
                        label="Matrícula"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-text-field
                        v-model="itemAtual.validade"
                        label="Validade"
                        outlined
                        type="date"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" class="mb-2">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-autocomplete
                        v-model="itemAtual.medico"
                        label="Médico(a) Responsável "
                        outlined
                        :items="medicos"
                        item-text="first_name"
                        item-value="id"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-autocomplete
                        v-model="itemAtual.cid"
                        label="Código do CID"
                        outlined
                        :items="cids"
                        item-text="nome"
                        item-value="id"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-autocomplete 
                        v-model="itemAtual.cid" 
                        label="Descrição do CID" outlined 
                        :items="cids" 
                        item-text="descricao" 
                        item-value="id"
                        return-object
                    ></v-autocomplete>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field 
                    v-model="itemAtual.responsavel"
                    label="Responsável pelo Paciente" 
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field 
                        v-model="itemAtual.telefone" 
                        label="Telefone" 
                        outlined></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field 
                        v-model="itemAtual.email" 
                        label="E-mail" 
                        outlined></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4" :cols="12">
                    <v-text-field 
                        v-model="itemAtual.alergia" 
                        label="Alergia" 
                        outlined></v-text-field>
                </v-col>
                <v-col :xs="12" :sm="12" :md="2" :lg="2" :xl="2" :cols="12">
                    <v-autocomplete 
                        outlined 
                        :loading="loading_tiposanguineo" 
                        v-model="itemAtual.tiposanguineo" 
                        label="Tipo Sanguíneo"
                        :items="tiposanguineo" 
                        item-text="nome" 
                        item-value="id" 
                        @change="get_tiposanguineo()" 
                        return-object
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
        <!-- Deixar o botõa de SAlvar apenas na parte superior da tela. -->
        <!-- <v-card-actions>
            <v-row>
                <v-col cols="11" class="text-end">
                    <v-btn icon @click="gravar()"><v-icon>mdi-content-save</v-icon></v-btn>
                </v-col>
                <v-col cols="1" class="text-end">
                    <v-btn v-if="btn_salvar" icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
                    
                    <v-progress-circular
                        v-show="btn_salvar"
                        :indeterminate="btn_salvar"
                        color="green"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-card-actions> -->
    </v-card>
</template>
<script>

import api from '@/http'
import { mapGetters } from 'vuex';
export default {
    name:"Paciente",
    components:{
        MyCardTitle: ()=>import('../../../uteis/card_title.vue'),
    },
    props:{
        itemAtual: {
            type:Object,
            default: null
        }
    },
    data:() => ({
        dialog:false,
        loading_plano: false,
        btn_salvar: false,
        operadoras:[],
        loading_equipe_multidisciplinar:false,
        planos:[],
        medicos:[],
        orgao_exp:[],
        cids:[],
        modalidade:[],
        tiposanguineo:[],
        loading_operadora: false,
        loading_modalidade: false,
        loading_tiposanguineo: false,
    }),
    methods:{
        get_operadoras(){
            this.loading_operadora = true;
            api('operadoras/operadoras/')
            .then((e) => { 
                this.operadoras = e.data
                this.get_planos()
            })
            .finally(()=>{ this.loading_operadora = false; });
        },
        get_planos(){  
            this.loading_plano = true
            if(this.itemAtual.operadora!==undefined){
                let id = this.itemAtual.operadora.id
                api(`operadoras/planos/?operadora=${id}`)
                .then((e) => { this.planos = e.data })      
                .finally(()=>{ this.loading_plano = false })
            }
        },
        get_medico(){  
            this.loading_plano = true
            api(`contas/medicos/`)
            .then((e) => {
                this.medicos = e.data
            })      
            .finally(()=>{ this.loading_plano = false; });
        },
        get_orgao_exp(){  
            api('uteis/orgao_exp/')
            .then((res)=>{
                this.orgao_exp = res.data
            })
            
        },
        get_modalidade(){
            api('uteis/modalidades/')
            .then((res)=>{
                this.modalidade = res.data
            })
        },
        get_geolocalizacao(){
            api('uteis/modalidades/')
            .then((res)=>{
                this.geolocalizacao = res.data
            })
        },
        get_tiposanguineo() {
            api('uteis/tiposanguineo/')
                .then((res) => {
                    this.tiposanguineo = res.data
                })
        },
        get_cids(){  
            api('uteis/cids/')
            .then((res)=>{
                this.cids = res.data
            })
        },
        iniciar(){
            this.get_orgao_exp()
            this.get_operadoras() 
            this.get_medico()
            this.get_cids()
            this.get_modalidade()
            this.get_geolocalizacao()
            this.get_tiposanguineo()
        },
        gravar(nao_fechar=false){
            this.loading_equipe_multidisciplinar = nao_fechar
            this.itemAtual.equipe_multi_disciplinar = this.check_emd
            this.btn_salvar = true
            if(this.itemAtual.id==null){
                api
                .post("pacientes/listar/",this.itemAtual)
                .then((e)=>{
                    console.log("post",e.data)
                    this.$emit("refresh_pacientes")
                    this.$emit("change_tab")
                    this.$emit('emitpaciente',e.data)
                    this.dialog = nao_fechar
                }).catch((err)=>{console.log('erro ',err)})
                .finally(()=>{
                    this.btn_salvar = false
                    this.loading_equipe_multidisciplinar = false
                });
            }else{
                api
                .put(`pacientes/paciente/${this.itemAtual.id}/`,this.itemAtual)
                .then((res)=>{
                    this.$emit("refresh_pacientes")
                    this.itemAtual.equipe_multi_disciplinar = res.data.equipe_multi_disciplinar
                }).catch((err)=>{console.log('erro ',err)})
                .finally(()=>{this.loading_equipe_multidisciplinar = false});
            }

        },
        modifica_data(){
            let p = this.itemAtual
            if(p.data_nascimento !== null){
                let a  = this.itemAtual.data_nascimento.split("/")
                let dtn = `${a[2]}-${a[1]}-${a[0]}`
                this.itemAtual.data_nascimento = dtn
            }
            if(p.validade !== null){
                let b  = this.itemAtual.validade.split("/")
                let val = `${b[2]}-${b[1]}-${b[0]}`
                this.itemAtual.validade = val
            }
            
        }
    },
    computed:{
        ...mapGetters(['paciente'])
    },
    watch:{
        check_emd(val){
            val
            let values = this.itemAtual.equipe_multi_disciplinar
            if(values!==undefined){
                let obj = Object.values(this.equipe_multidisciplinar).map((e)=>
                    Object.values(values).map((v)=>v.id)
                    .find((x) => x == e.id)   
                )
                val = obj.filter(item=>!!item)
            }
        },
    },
    mounted(){  
        this.iniciar()
    }
}
</script>